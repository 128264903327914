










import { Component, Vue } from 'vue-property-decorator';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class InquiryForm extends Vue {
}
