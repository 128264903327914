






import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component
export default class GoogleReviews extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrElfsightIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://static.elfsight.com/platform/platform.js';
      scr.async = true;
      scr.defer = true;
      scr.setAttribute('data-use-service-core', '');
      scr.id = 'scrElfsightIngegration';

      scr.onload = () => {
        console.log('Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
